import environment from '../../.configs/app/environment'

const debugMode = environment.app.env !== 'production';

export const initGoogleAnalytics = (id) => {
  if (typeof window === 'undefined') return;

  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

  const script2 = document.createElement('script');
  script2.textContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${id}', { 'debug_mode': ${debugMode} });
    `;

  document.head.appendChild(script1);
  document.head.appendChild(script2);
};

/**
 * Track a custom event in Google Analytics
 * @param {string} eventName - The name of the event to track
 * @param {Object} eventParams - Optional parameters to include with the event
 * @example
 * // Track a simple event
 * trackEvent('button_click');
 * 
 * // Track an event with parameters
 * trackEvent('sign_up', { method: 'email', user_type: 'host' });
 */
export const trackEvent = (eventName, eventParams = {}) => {
  if (typeof window === 'undefined' || !window.gtag) {
    console.warn('Google Analytics not initialized or running in SSR');
    return;
  }

  let pageName = window.location.pathname;
  if (pageName == '/') {
    pageName = 'Home'
  } else if (pageName.includes('/')) {
    pageName = pageName.slice(1).replaceAll('/', '_')
  }
 
  eventParams = {
    page_path: pageName,
    ...eventParams,
  }

  if (debugMode) {
    console.log('Debug mode: eventParams:', eventParams, 'eventName:', eventName)
  }
  

  window.gtag('event', eventName, eventParams);

  if (debugMode) {
    console.log(`[GA Event] ${eventName}`, eventParams);
  }
};