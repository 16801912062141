// -- External libraries
import {
    createEffect,
    createSignal,
    Show,
} from 'solid-js'
import { PublicTemplateContext } from './public-template-component-context.js' // Adjusted import

import Routes from '../../../routes/router'
import { usePublicTemplateComponentController } from './public-template-component-controller.js'
import './public-template-component.scss'
import Logo from '/assets/logo/logo.svg'
import {
    useLocation,
    useNavigate,
} from '@solidjs/router'
import BarAtomComponent from '../../atoms/bar-atom/bar-atom-component.jsx'
import ButtonAtomComponent from '../../atoms/button-atom/button-atom-component.jsx'
import ImageAtomComponent from '../../atoms/image-atom/image-atom-component.jsx'
import LinkAtomComponent from '../../atoms/link-atom/link-atom-component.jsx'
import SeparatorAtomComponent from '../../atoms/separator-atom/separator-atom-component.jsx'
import MenuMoleculeComponent from '../../molecules/menu-molecule/menu-molecule-component.jsx'
import FooterOrganismComponent from '../../organisms/footer-organism/footer-organism.jsx'
import AuthModal from '../../../modals/auth-modal/auth-modal.jsx'
import CurrencyDropdown from '../../lodgr/currency-dropdown/currency-dropdown.jsx'
import { trackEvent } from '../../../../utilities/use-analytics.js'

export default function PublicTemplateComponent (props) {
    const controller = usePublicTemplateComponentController(props)
    const location = useLocation()
    const navigate = useNavigate()

    const [
        selected,
        setSelected,
    ] = createSignal(false)

    createEffect(() => {
        if (location.pathname.startsWith('/property/') || location.pathname === '/properties') {
            setSelected(true)
        } else {
            setSelected(false)
        }
    })

    return (
        <PublicTemplateContext.Provider value={controller.value}>
            <div class={['public-template-component', controller.styles.class].join(' ')}>
                <Show when={controller.state.showHeader}>
                    <div
                        class="public-template-component__header-wrapper"
                        classList={{
                            'public-template-component__header-wrapper--checkout': controller.state.showCurrencyHeader,
                            'public-template-component__header-wrapper--dashboard': controller.state.showSidebar,
                        }}
                    >
                        <BarAtomComponent
                            class="public-template-component__header"
                            classList={{
                                'public-template-component__header--checkout': controller.state.showCurrencyHeader,
                                'public-template-component__header--dashboard': controller.state.showSidebar,
                            }}
                        >
                            <LinkAtomComponent class="public-template-component__header__link" href="/">
                                <ImageAtomComponent class="public-template-component__header__link__logo" src={Logo} />
                            </LinkAtomComponent>
                            <LinkAtomComponent class="public-template-component__header__link--mobile" href="/">
                                <ImageAtomComponent
                                    class="public-template-component__header__link--mobile__logo"
                                    src={Logo}
                                />
                            </LinkAtomComponent>
                            <ButtonAtomComponent
                                onclick={controller.toggleHamburgerMenu}
                                class="public-template-component__header__hamburger"
                            >
                                <ImageAtomComponent
                                    src={
                                        controller.showHamburgerMenu()
                                            ? '/assets/icons/close-filled.svg'
                                            : '/assets/icons/hamburger-outlined.svg'
                                    }
                                />
                            </ButtonAtomComponent>
                            <Show when={controller.state.showHeaderMenu}>
                                <MenuMoleculeComponent class="public-template-component__header__menu">
                                    {/* <LinkAtomComponent href="/">How It Works</LinkAtomComponent> */}
                                    {/* <LinkAtomComponent href="/about-us">About Us</LinkAtomComponent> */}
                                    <LinkAtomComponent href="/faq/general">Support</LinkAtomComponent>
                                    <Show
                                        when={!controller.isAuthenticated()}
                                        fallback={
                                            <ButtonAtomComponent
                                                class="public-template-component__header__button"
                                                onClick={controller.navigateToDashboard}
                                            >
                                                Open Lodgr
                                            </ButtonAtomComponent>
                                        }
                                    >
                                        <ButtonAtomComponent
                                            class="public-template-component__header__button"
                                            onClick={controller.openAuthModal}
                                        >
                                            Login
                                        </ButtonAtomComponent>
                                    </Show>
                                </MenuMoleculeComponent>
                            </Show>
                            <Show when={controller.state.showCurrencyHeader}>
                                <div class="public-template-component__checkout-header">
                                    <CurrencyDropdown />

                                    <div
                                        class="public-template-component__checkout-header__user-image-wrapper"
                                        ref={controller.setProfileReference}
                                        onMouseEnter={controller.showProfileTooltip}
                                        onMouseLeave={controller.hideProfileTooltip}
                                    >
                                        <img
                                            src={'/assets/icons/user.svg'}
                                            class="public-template-component__checkout-header__user-image"
                                        />

                                        <div
                                            class="public-template-component__checkout-header__user-image-tooltip"
                                            classList={{
                                                'public-template-component__checkout-header__user-image-tooltip--hidden':
                                                    !controller.profileTooltipVisible(),
                                                'public-template-component__checkout-header__user-image-tooltip--open-left':
                                                    controller.showTooltipLeft(),
                                            }}
                                            ref={controller.setProfileTooltipReference}
                                        >
                                            <div class="public-template-component__checkout-header__user-image-tooltip__content">
                                                {controller.bookingStore.getEmail()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Show>
                        </BarAtomComponent>
                    </div>
                </Show>

                <Show when={controller.showHamburgerMenu()}>
                    <BarAtomComponent class="public-template-component__overlay" direction="vertical">
                        <Show when={!controller.isAuthenticated()}>
                            <MenuMoleculeComponent
                                class="public-template-component__overlay__menu"
                                direction="vertical"
                            >
                                {/* <LinkAtomComponent href="/">How It Works</LinkAtomComponent> */}
                                <LinkAtomComponent href="/faq/general">FAQ</LinkAtomComponent>
                                <LinkAtomComponent 
                                    href="/login" 
                                    onClick={() => trackEvent('clickCTAheader_Login')}
                                >
                                    Login
                                </LinkAtomComponent>
                            </MenuMoleculeComponent>
                        </Show>

                        <MenuMoleculeComponent class="public-template-component__overlay__menu" direction="vertical">
                            <Show when={controller.isAuthenticated()}>
                                <Show when={controller.countStore.get('numOfStays') >= 1}>
                                    <LinkAtomComponent {...controller.getMenuItemProps('/stays')} href="/stays">
                                        Stays
                                    </LinkAtomComponent>
                                </Show>
                                <Show when={controller.countStore.get('numOfProperties') > 1}>
                                    <LinkAtomComponent
                                        {...controller.getMenuItemProps('/properties')}
                                        href="/properties"
                                    >
                                        Properties
                                    </LinkAtomComponent>
                                    <LinkAtomComponent {...controller.getMenuItemProps('/bookings')} href="/bookings">
                                        Bookings
                                    </LinkAtomComponent>
                                </Show>
                                <LinkAtomComponent {...controller.getMenuItemProps('/account')} href="/account">
                                    Account
                                </LinkAtomComponent>

                                {(() => {
                                    // Determine the text content for LinkAtomComponent
                                    let linkText = ''
                                    if (
                                        !controller.countStore.get('numOfProperties')
                                        && !controller.countStore.get('numOfStays')
                                    ) {
                                        linkText = 'Welcome'
                                    } else if (
                                        controller.countStore.get('numOfProperties')
                                        && !controller.countStore.get('numOfStays')
                                    ) {
                                        linkText = 'Become a Guest'
                                    } else if (
                                        !controller.countStore.get('numOfProperties')
                                        && controller.countStore.get('numOfStays')
                                    ) {
                                        linkText = 'Become a Host'
                                    }

                                    return (
                                        <>
                                            {linkText && (
                                                <SeparatorAtomComponent class="public-template-component__overlay__separator" />
                                            )}

                                            <LinkAtomComponent href="/welcome">{linkText}</LinkAtomComponent>
                                        </>
                                    )
                                })()}

                                <SeparatorAtomComponent class="public-template-component__overlay__separator" />

                                <LinkAtomComponent href="/faq/general">FAQ</LinkAtomComponent>
                                <LinkAtomComponent href="#" onClick={controller.logout}>
                                    Log Out
                                </LinkAtomComponent>
                            </Show>
                        </MenuMoleculeComponent>
                    </BarAtomComponent>
                </Show>
                <Show when={!controller.showHamburgerMenu()}>
                    <div
                        class="public-template-component__main"
                        classList={{
                            'public-template-component__main--overflow': controller.state.showSidebar,
                        }}
                    >
                        {/* dashboard side menu */}
                        <Show when={controller.state.showSidebar}>
                            <BarAtomComponent class="public-template-component__main__sidebar" direction="vertical">
                                <MenuMoleculeComponent
                                    class="public-template-component__main__sidebar__menu"
                                    direction="vertical"
                                >
                                    <Show when={controller.countStore.get('numOfProperties') > 0}>
                                        <LinkAtomComponent
                                            {...controller.getMenuItemProps('/properties')}
                                            classList={{
                                                'public-template-component__main__sidebar__menu__item--selected':
                                                    selected(),
                                            }}
                                        >
                                            Properties
                                        </LinkAtomComponent>
                                        <LinkAtomComponent {...controller.getMenuItemProps('/bookings')}>
                                            Bookings
                                        </LinkAtomComponent>
                                    </Show>
                                    <Show when={controller.countStore.get('numOfStays') > 0}>
                                        <LinkAtomComponent {...controller.getMenuItemProps('/stays')}>
                                            Stays
                                        </LinkAtomComponent>
                                    </Show>
                                    <LinkAtomComponent {...controller.getMenuItemProps('/account')}>
                                        Account
                                    </LinkAtomComponent>
                                </MenuMoleculeComponent>
                                <SeparatorAtomComponent class="public-template-component__main__sidebar__separator" />
                                <MenuMoleculeComponent
                                    class="public-template-component__main__sidebar__menu"
                                    direction="vertical"
                                >
                                    <LinkAtomComponent href="/welcome">
                                        {!controller.countStore.get('numOfProperties')
                                        && !controller.countStore.get('numOfStays')
                                            ? 'Welcome'
                                            : ''}
                                        {controller.countStore.get('numOfProperties')
                                        && !controller.countStore.get('numOfStays')
                                            ? 'Become a Guest'
                                            : ''}
                                        {!controller.countStore.get('numOfProperties')
                                        && controller.countStore.get('numOfStays')
                                            ? 'Become a Host'
                                            : ''}
                                    </LinkAtomComponent>
                                </MenuMoleculeComponent>
                                <MenuMoleculeComponent
                                    class="public-template-component__main__sidebar__menu--last"
                                    direction="vertical"
                                >
                                    {/* <LinkAtomComponent href='/contact' disabled={true}>Contact</LinkAtomComponent> */}
                                    <LinkAtomComponent target="_blank" href="/faq/general">
                                        FAQ
                                    </LinkAtomComponent>
                                    <LinkAtomComponent href="" onClick={controller.logout}>
                                        Log Out
                                    </LinkAtomComponent>
                                </MenuMoleculeComponent>
                            </BarAtomComponent>
                        </Show>
                        <div class="public-template-component__main__children">
                            <Show when={controller.countStore.get('isLoaded') == true}>
                                <Routes />
                            </Show>
                        </div>
                    </div>
                    <Show when={controller.state.showFooter}>
                        <FooterOrganismComponent class="public-template-component__footer" />
                    </Show>
                </Show>
                <Show when={controller.showAuthModal()}>
                    <AuthModal onClose={controller.closeAuthModal} />
                </Show>
            </div>
        </PublicTemplateContext.Provider>
    )
}
